import { Button, InputText, Typography, TypographyLink, VStack, View } from '@ornikar/kitt-universal';
import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import type { ReactNode } from 'react';
import { getVehicleByLicensePlate } from '../../../apis/vehicules';
import { ScreenTemplateWithoutForm } from '../../../components/ScreenTemplates/ScreenTemplateWithoutForm/ScreenTemplateWithoutForm';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { sendEvent } from '../../../utils/mixpanel';
import EUIcon from './EUIcon.svg';

export function VehicleLicensePlateScreen(): ReactNode {
  const [isLoading, setIsLoading] = useState(false);

  const send = useSubscriptionFsmDispatch();
  const { context } = useSubscriptionFsmState();

  const [licensePlate, setLicensePlate] = useState<string | undefined>(context.answers?.vehiculeImmatriculation);

  const [error, setError] = useState<string | undefined>();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const isSamePlate = licensePlate === context.answers.vehiculeImmatriculation;

      const vehicleData = isSamePlate
        ? { vehiculeVersionOptions: context.availableVersions }
        : await getVehicleByLicensePlate(licensePlate || '');

      send({
        type: Event.ANSWER,
        answers: { ...vehicleData },
      });
    } catch (error_) {
      Sentry.captureException(error_);
      setError('Votre numéro de plaque ne semble pas fonctionner ? Essayez la recherche par marque');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensePlate]);

  const isFieldEmpty = licensePlate?.length === 0;

  return (
    <ScreenTemplateWithoutForm
      title="Quelle est votre plaque d'immatriculation&nbsp;?"
      submitButtonComponent={
        <Button type="primary" disabled={Boolean(error) || isFieldEmpty} onPress={handleSubmit}>
          Confirmer
        </Button>
      }
    >
      <View height="kitt.10" overflow="hidden" marginY="kitt.3" borderRadius="kitt.2" position="relative">
        <VStack
          alignItems="center"
          padding={6}
          backgroundColor="kitt.primary"
          height="100%"
          color="white"
          zIndex={1}
          position="absolute"
        >
          <img width={14} src={EUIcon} alt="european union" />
          <View>
            <Typography.Text color="white" base="body-xs" font-weight={600} variant="regular">
              F
            </Typography.Text>
          </View>
        </VStack>
        <InputText
          autoFocus
          style={{ paddingLeft: 34, width: '100%' }}
          testID="field.VehicleLicensePlateScreen.vehicleImmatriculation"
          placeholder="AA123AA"
          disabled={isLoading}
          value={licensePlate}
          onChangeText={(text) => setLicensePlate(text.toUpperCase())}
          onBlur={() => {
            if (isFieldEmpty) {
              setError('Veuillez saisir une plaque valide.');
            }
          }}
          onFocus={() => setError(undefined)}
        />
      </View>
      {Boolean(error) && (
        <View marginLeft="kitt.3">
          <Typography.Text color="danger" base="body-small">
            {error}
          </Typography.Text>
        </View>
      )}

      <View marginTop="kitt.2">
        <Typography.Text color="black-light">
          Gagnez du temps en cherchant par plaque ! Vous ne la connaissez pas ? Cherchez{' '}
          <TypographyLink
            variant="bold"
            color="primary"
            onPress={() => {
              sendEvent('Go To Screen', {
                from_state: 'VEHICULE_LICENSE_PLATE',
                to_state: 'VEHICULE_BRAND',
              });
              send({ type: Event.SKIP });
            }}
          >
            par&nbsp;marque
          </TypographyLink>
          .
        </Typography.Text>
      </View>
    </ScreenTemplateWithoutForm>
  );
}
