import { Button } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

interface DriverDetailsSubmitButtonProps {
  isPrimaryDriver: boolean;
}

export function DriverDetailsSubmitButton({ isPrimaryDriver }: DriverDetailsSubmitButtonProps): ReactNode {
  const { errors = {} } = useFormState();
  const hasError = Object.keys(errors).length > 0;
  const { submit } = useForm();

  return (
    <Button type="primary" disabled={hasError} onPress={submit}>
      {isPrimaryDriver ? (
        <FormattedMessage id="DriverDetailsSubmitButton.label.getOffer" defaultMessage="Obtenir mon tarif" />
      ) : (
        <FormattedMessage id="DriverDetailsSubmitButton.label.continue" defaultMessage="Continuer" />
      )}
    </Button>
  );
}
